import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { SimpleBox } from "../../Components/CustomBox";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

import Cookies from "universal-cookie";
import ProfileAvatar from "../Avatar/ProfileAvatar";
import RequestFeatureDialog from "../Dialog/RequestFeature";
import SubmitFeedback from "../Dialog/SubmitFeedback";
import { Typography } from "@mui/material";

import { SecondaryButton } from "../../Components/CustomButton";
import Logout from "../../Helper/Logout/Logout";
import BatterySmart from "../../Assets/Icons/Landing/BatterySmart.svg";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import PasswordReset from "../Dialog/PasswordReset";
import ChangeTimeZone from "../Dialog/ChangeTimeZone";

export default function BasicPopover(props) {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  const [anchorElChangeTimezone, setAnchorElChangeTimezone] = useState(null);
  const [openChangeTimezone, setOpenChangeTimezone] = useState(false);
  const handleOpenChangeTimezone = (event) => {
    setAnchorElChangeTimezone(event.currentTarget);
    setOpenChangeTimezone(true);
  };
  const handleCloseChangeTimezone = () => {
    setOpenChangeTimezone(false);
  };

  const [anchorElRequestFeatureDialog, setAnchorElRequestFeatureDialog] =
    useState(null);
  const [openRequestFeatureDialog, setOpenRequestFeatureDialog] =
    useState(false);
  const handleOpenRequestFeatureDialog = (event) => {
    setAnchorElRequestFeatureDialog(event.currentTarget);
    setOpenRequestFeatureDialog(true);
  };
  const handleCloseRequestFeatureDialog = () => {
    setOpenRequestFeatureDialog(false);
  };

  const [anchorElSendFeedbackDialog, setAnchorElSendFeedbackDialog] =
    useState(null);
  const [openSendFeedbackDialog, setOpenSendFeedbackDialog] = useState(false);
  const handleOpenSendFeedbackDialog = (event) => {
    setAnchorElSendFeedbackDialog(event.currentTarget);
    setOpenSendFeedbackDialog(true);
  };
  const handleCloseSendFeedbackDialog = () => {
    setOpenSendFeedbackDialog(false);
  };

  const [anchorElPasswordReset, setAnchorElPasswordReset] = useState(null);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const handleOpenPasswordReset = (event) => {
    setAnchorElPasswordReset(event.currentTarget);
    setOpenPasswordReset(true);
  };
  const handleClosePasswordReset = () => {
    setOpenPasswordReset(false);
  };

  return (
    <Popover
      id={props.idProfilePopup}
      open={props.openProfilePopup}
      anchorEl={props.anchorElProfilePopup}
      onClose={props.handleCloseProfilePopup}
      container={props.anchorElProfilePopup.parentNode}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      elevation={2}
    >
      <SimpleBox sx={{ p: 2 }}>
        <Box sx={{ width: "100%", minWidth: 200, bgcolor: "background.paper" }}>
          <List>
            <ListItem disablePadding>
              <SimpleBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pl: "16px",
                }}
              >
                <SimpleBox>
                  <ProfileAvatar showIcon={true} />
                </SimpleBox>
                <SimpleBox>
                  <Typography variant="textSubtitlePurple" sx={{ ml: 2 }}>
                    {cookies.get("name") === undefined
                      ? ""
                      : cookies.get("name")}
                  </Typography>
                </SimpleBox>
              </SimpleBox>
            </ListItem>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />

            <ListItem disablePadding>
              <SimpleBox>
                <SecondaryButton
                  sx={{
                    width: 200,
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  onClick={handleOpenRequestFeatureDialog}
                >
                  <Typography variant="textSubtitlePurple">
                    Request Feature
                  </Typography>
                </SecondaryButton>
              </SimpleBox>
            </ListItem>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />

            <ListItem disablePadding>
              <SimpleBox>
                <SecondaryButton
                  sx={{
                    width: 200,
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  onClick={handleOpenChangeTimezone}
                >
                  <Typography variant="textSubtitlePurple">
                    Change Timezone
                  </Typography>
                </SecondaryButton>
              </SimpleBox>
            </ListItem>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />

            <ListItem disablePadding>
              <SimpleBox>
                <SecondaryButton
                  sx={{
                    width: 200,
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  onClick={handleOpenSendFeedbackDialog}
                >
                  <Typography variant="textSubtitlePurple">
                    Submit Your Feedback
                  </Typography>
                </SecondaryButton>
              </SimpleBox>
            </ListItem>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />

            <ListItem disablePadding>
              <SimpleBox>
                <SecondaryButton
                  sx={{
                    width: 200,
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  onClick={handleOpenPasswordReset}
                >
                  <Typography variant="textSubtitlePurple">
                    Reset Your Password
                  </Typography>
                </SecondaryButton>
              </SimpleBox>
            </ListItem>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />

            <ListItem disablePadding>
              <SimpleBox>
                <SecondaryButton
                  sx={{
                    width: 200,
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    Logout(true, handleNavigate);
                  }}
                >
                  <Typography variant="textSubtitlePurple">Logout</Typography>
                </SecondaryButton>
              </SimpleBox>
            </ListItem>

            {/* <Divider sx={{ mt: 1.5, mb: 1.5 }} /> */}

            {/* <ListItem disablePadding>
              <SimpleBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor:ThemeProperties.purple,
                  width:"100%",
                  height:60
                }}
              >
                <img src={BatterySmart} alt="BatterySmart Logo" height={30} ></img>
              </SimpleBox>
            </ListItem> */}
          </List>
        </Box>
      </SimpleBox>
      {openChangeTimezone && (
        <ChangeTimeZone
          anchorElChangeTimezone={anchorElChangeTimezone}
          openChangeTimezone={openChangeTimezone}
          handleCloseChangeTimezone={handleCloseChangeTimezone}
          openNotification={props.openNotification}
        />
      )}

      {openRequestFeatureDialog && (
        <RequestFeatureDialog
          anchorElRequestFeatureDialog={anchorElRequestFeatureDialog}
          openRequestFeatureDialog={openRequestFeatureDialog}
          handleCloseRequestFeatureDialog={handleCloseRequestFeatureDialog}
        />
      )}
      {openSendFeedbackDialog && (
        <SubmitFeedback
          anchorElSendFeedbackDialog={anchorElSendFeedbackDialog}
          openSendFeedbackDialog={openSendFeedbackDialog}
          handleCloseSendFeedbackDialog={handleCloseSendFeedbackDialog}
        />
      )}

      {openPasswordReset && (
        <PasswordReset
          anchorElPasswordReset={anchorElPasswordReset}
          openPasswordReset={openPasswordReset}
          handleClosePasswordReset={handleClosePasswordReset}
        />
      )}
    </Popover>
  );
}
