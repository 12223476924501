import React from "react";
import { Avatar, IconButton, Typography } from "@mui/material";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import bsIcon from "../../Assets/Icons/bsIcon2.svg";
import { ThemeProperties } from "../../Theme/ThemeProperties";

function ProfileAvatar(props) {
  const cookies = new Cookies();

  const GetAvatar = () => {
    return (
      <Avatar
        style={{
          height: "27px",
          width: "27px",
          backgroundColor: "#FF977A",
          border: `  1px solid ${
            props.openProfilePopup
              ? ThemeProperties.purple
              : ThemeProperties.white
          }`,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            textAlign: "center",
            color: "#fff",
            fontSize: "14px",
          }}
        >
          {cookies.get("name") === undefined
            ? removeAccessToken()
            : cookies.get("name").charAt(0)}
        </Typography>
      </Avatar>
    );
  };

  return (
    <>
      {!props.showIcon ? (
        <IconButton>
          <GetAvatar/>
        </IconButton>
      ) : (
        <GetAvatar/>
      )}
    </>
  );
}

export default ProfileAvatar;
